class Accordion {
  constructor() {
    this.accordion = document.querySelector(".accordion");
    if (this.accordion) {
      if (this.accordion.querySelectorAll(".accordion__item").length > 1) {
        this.init();
      }
    }
  }

  init() {
    this.accordion.addEventListener("click", (ev) => {
      if (ev.target.classList.contains("accordion__question")) {
        let button = ev.target;
        button.classList.toggle('accordion__question--toggled')
        if (button.classList.contains('accordion__question--toggled')) {
          button.nextElementSibling.style.gridTemplateRows = "1fr";
          open = true;
        } else {
          button.nextElementSibling.style.gridTemplateRows = "0fr";
          open = false;
        }
      }
    });
  }
}

export default Accordion;