import Navigation from "./modules/Navigation"
import MapBox from "./modules/MapBox"
import Accordion from "./modules/Accordion"
import VacanciesFilter from "./modules/VacanciesFilter"
import ProductCategoryTabs from "./modules/ProductCategoryTabs"
import ProductCard from "./modules/ProductCard"
import NewsFilter from "./modules/NewsFilter"
import ServiceVideoLightbox from "./modules/ServiceVideoLightbox"
import Filter from './modules/Filter'
import SearchTabs from "./modules/SearchTabs"
import InfoBox from "./modules/InfoBox"
import TableNavigation from "./modules/TableNavigation"
import VideoHandler from "./modules/VideoHandler"

document.addEventListener('DOMContentLoaded', () => {
  const navigation = new Navigation()
  const mapBox = new MapBox()
  const accordion = new Accordion()
  const vacanciesFilter = new VacanciesFilter()
  const productCategoryTabs = new ProductCategoryTabs()
  const productCard = new ProductCard()
  const newsFilter = new NewsFilter()
  const serviceVideoLightbox = new ServiceVideoLightbox()
  // Filter: filter-selector og liste-selector
  const employeesFilterEl = document.querySelector('.employees__filter')
  if (employeesFilterEl) {
    const employeeFilter = new Filter(employeesFilterEl, document.querySelector('.employees__list'))
  }
  const partnersFilterEl = document.querySelector('.partners__filter')
  if (partnersFilterEl) {
    const partnersFilter = new Filter(partnersFilterEl, document.querySelector('.partners__list'))
  }
  const officesFilterEl = document.querySelector('.offices__filter')
  if (officesFilterEl) {
    const officesFilter = new Filter(officesFilterEl, document.querySelector('.offices__list'))
  }
  let is_search_page = document.querySelector('.search-page')
  if (is_search_page) {
    const searchTabs = new SearchTabs()
  }
  let has_info_box = document.querySelector('.infobox')
  if (has_info_box) {
    const infoBox = new InfoBox()
  }
  const has_product_tables = document.querySelector('.product-table')
  if (has_product_tables) {
    new TableNavigation()
  }

  const has_videos = document.querySelectorAll('.service-videos')
  if (has_videos.length > 0) {
    has_videos.forEach(video_module => {
      new VideoHandler(video_module)
    })
  }
})