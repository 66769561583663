class VacanciesFilter {
  constructor() {
    this.container = document.querySelector('.filter--vacancies')
    this.cards = document.querySelectorAll(".vacancy")
    this.location = ""
    this.position = ""
    if (this.container) {
      this.listen()
    }
  }

  listen() {
    this.container.addEventListener("change", (ev) => {
      if (ev.target.classList.contains("filter__selector")) {
        if (ev.target.dataset.type === "location") {
          this.filterLocation(ev.target.value)
        }
        if (ev.target.dataset.type === "position") {
          this.filterPosition(ev.target.value)
        }
      }
    })
  }

  filterPosition(value) {
    this.position = value
    if (value === "") {
      this.cards.forEach(card => {
        if (card.dataset.location === this.location || this.location === "") {
          card.classList.remove('hidden')
        } else {
          card.classList.add('hidden')
        }
      })
      this.position = ""
      return
    }

    this.cards.forEach(card => {
      if ((card.dataset.position === value || !this.position) && (card.dataset.location === this.location || this.location === "")) {
        card.classList.remove('hidden')
      } else {
        card.classList.add('hidden')
      }
    })
    this.position = value
  }

  filterLocation(value) {
    this.location = value
    if (value === "") {
      this.cards.forEach(card => {
        if (card.dataset.position === this.position || this.position === "") {
          card.classList.remove('hidden')
        } else {
          card.classList.add('hidden')
        }
      })
      this.location = ""
      return
    }

    this.cards.forEach(card => {
      if ((card.dataset.location === value) && (card.dataset.position === this.position || this.position === "")) {
        card.classList.remove('hidden')
      } else {
        card.classList.add('hidden')
      }
      this.location = value
    })
  }
}


export default VacanciesFilter;