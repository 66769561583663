class ProductCard {

  constructor() {
    if (document.querySelector('.product')) {
      this.listen()
    }
  }

  listen() {
    addEventListener('click', ev => {
      const parent = ev.target.closest('article')
      if (ev.target.dataset.action === "downloads") {
        const currentlyOpen = document.querySelector('.product__files[data-active]')
        if (currentlyOpen) currentlyOpen.removeAttribute('data-active')
        parent.querySelector('.product__files').setAttribute('data-active', '')
      }
      if (ev.target.dataset.action === "description") {
        const currentlyOpen = document.querySelector('.product__description[data-active]')
        if (currentlyOpen) currentlyOpen.removeAttribute('data-active')
        parent.querySelector('.product__description').setAttribute('data-active', '')
      }
      if (ev.target.dataset.action === 'exit') {
        parent.querySelector('[data-active]').removeAttribute('data-active')
      }
      if (ev.target.dataset.action === 'download-all') {
        const elements = parent.querySelectorAll('.product__files li:not(.product__files-header) a')
        let urls = []
        elements.forEach(el => {
          urls.push(el.getAttribute('href'))
        })
        const options = {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              'files': urls,
          })
        }
        fetch('/api/zipit', options)
          .then(res => {
            res.json().then(data => {
              window.open(data.filename, "_blank")
            })
          })
      }
    })
  }


}

export default ProductCard