class ServiceVideoLightbox {

  constructor() {
    if (document.querySelector('.service-videos')) {
      this.listen()
      this.video = document.querySelector('.services-lightbox__video')
      this.iframe = document.querySelector('.services-lightbox__iframe')
      this.lightbox = document.querySelector('.services-lightbox')
    }
  }

  listen() {
    addEventListener('click', ev => {
      if (ev.target.classList.contains('service-videos__item')) {
        const url = ev.target.dataset.url
        const type = ev.target.dataset.type
        let video_url = ""
        if (type == "local") {
          this.video.src = url
          this.iframe.classList.add('hidden')
          this.video.classList.remove('hidden')
        }
        if (type == "youtube" || type == "vimeo") {
          const id = ev.target.dataset.video_id
          if (type == "youtube") {
            video_url = "https://www.youtube.com/embed/"
          }
          if (type == "vimeo") {
            video_url = "https://player.vimeo.com/video/"
          }
          this.iframe.src = video_url + id
          this.iframe.classList.remove('hidden')
          this.iframe.height = (Math.min(window.innerWidth, 1140) * 0.5625) + "px";
          this.video.classList.add('hidden')
        }
        this.lightbox.classList.remove('hidden')
      }
      if (ev.target.classList.contains('services-lightbox') ||
          ev.target.classList.contains('services-lightbox__inner')) {
        this.closeLightbox()
      }
    })

    addEventListener('keydown', (ev) => {
      if (ev.key == "Escape") {
        this.closeLightbox()
      }
    })
  }

  closeLightbox() {
    this.lightbox.classList.add('hidden')
    this.iframe.classList.add('hidden')
    this.video.classList.add('hidden')
    this.video.src = ""
    this.iframe.src = ""
  }

}

export default ServiceVideoLightbox