class ProductCategoryTabs {

  constructor() {
    if (document.querySelector('.product-tabs')) {
      this.activeTab = window.location.hash ?? null
      this.init()
      this.listen()
    }
  }

  listen() {
    document.querySelector('.product-tabs').addEventListener('click', ev => {
      const link = ev.target
      if (ev.target.hasAttribute('href') && ev.target.getAttribute('href')[0] === '#') {
        const section = document.querySelector(`[data-tab="${link.href.split("#")[1]}"]`)
        this.activateTab(link, section)
        const offsetPosition = Math.floor((section.getBoundingClientRect().top + window.scrollY) - 107)
        section.scrollIntoView({
          block: 'start',
          behavior: 'smooth'
        })
      }
    })
  }

  init() {
    if (this.activeTab) {
      const link = document.querySelector(`[href="${this.activeTab}"]`)
      const section = document.querySelector(`[data-tab="${this.activeTab.substring(1)}"]`)
      this.activateTab(link, section)
    }
  }

  activateTab(activeLink, activeSection) {
    const currentLink = document.querySelector('a[data-active]')
    const currentSection = document.querySelector('section[data-active]')
    // sjekk om current == active - isåfall, return
    if (currentLink === activeLink) return
    // Fjern attributt fra current link og aktiv seksjon
    currentLink.removeAttribute('data-active')
    currentSection.removeAttribute('data-active')
    // legg til attributt på aktiv link og aktiv seksjon
    activeLink.setAttribute('data-active', '')
    activeSection.setAttribute('data-active', '')
    activeSection.scrollIntoView()
  }

}

export default ProductCategoryTabs