class NewsFilter {

  constructor() {
    if (document.querySelector('.filter--news')) {
      this.cards = document.querySelectorAll('.news-preview')
      this.selector = document.querySelector('.filter--news .filter__selector')
      this.init()
      this.listen()
    }
  }

  listen() {

    this.selector.addEventListener('change', ev => {
      if (ev.target.value === "") {
        this.cards.forEach(card => card.classList.remove('hidden'))
        return
      }
      this.hideAllAndShow(ev.target.value)
    })
  }

  hideAllAndShow(newTag) {
    this.cards.forEach(card => {
      if (card.dataset.post_tag === newTag) {
        card.classList.remove('hidden')
      } else {
        card.classList.add('hidden')
      }
    })
  }


  init() {
    // Hide taxonomies not in use
    let tags = []
    this.cards.forEach(card => {
      if (!tags.includes(card.dataset.post_tag)) {
        tags.push(card.dataset.post_tag)
      }
    })

    if (tags.length < 2) {
      this.selector.classList.add('hidden')
      return
    }

    this.selector.querySelectorAll('option').forEach(option => {
      if (!tags.includes(option.value)) {
        option.classList.add('hidden')
      }
    })
  }

}

export default NewsFilter