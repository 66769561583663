class MapBox {
  constructor() {
    this.mapContainer = document.querySelector('.mapbox')
    if (this.mapContainer) {
      this.map;
      this.marker;
      this.coordinates = new mapboxgl.LngLatBounds();
      this.init()
      this.map.on('render', () => {
        this.map.resize()
      })
      this.resizeInViewport()
    }
  }

  init() {
    mapboxgl.accessToken = "pk.eyJ1IjoicjhlZGdlIiwiYSI6ImNsbWtnN2JtNjAyanIybHRjc3owdGoweXcifQ.TKbfEvww_PTzkWR7aBFHCw";
    this.map = new mapboxgl.Map({
      container: "mapbox__map", // container ID
      style: "mapbox://styles/r8edge/clmkky3y601t801pb17v41rop", // Custom
      // style: "mapbox://styles/r8edge/clsx1ggf400aa01qz6azobd21", // Dark
      zoom: 9,
      projection: 'globe', // 3D Globe
      // projection: 'equirectangular'
    });


    document.querySelectorAll('.address').forEach((address) => {
      const color = address.dataset.color ?? "#004D72"
      this.marker = new mapboxgl.Marker({ color: color }).setLngLat([address.dataset.long, address.dataset.lat]).addTo(this.map)
      const popup = new mapboxgl.Popup({ offset: 25 })
        .setLngLat([address.dataset.long, address.dataset.lat])
        .addTo(this.map);
      if (address.children.length > 0) {
        popup.setHTML(address.innerHTML)
      }
      popup.addTo(this.map)
      this.marker.setPopup(popup);

      const element = this.marker.getElement();
      element.id = 'marker'
      element.addEventListener('click', () => popup.addTo(this.map));
      this.marker.setPopup(popup);
      this.marker.addTo(this.map);
      this.coordinates.extend([address.dataset.long, address.dataset.lat]);
      this.map.fitBounds(this.coordinates, {
        padding: 50,
        linear: false,
        speed: 2,
      });
    })
  }

  decodeHTML(input) {
    const doc = new DOMParser().parseFromString(input, "text/html")
    return doc.children[0].children[1].firstChild.innerHTML
  }

  resizeInViewport() {
    let options = {
      root: null,
      rootMargin: "0px",
      threshold: [0, .1, .2, .3, .4, .5, .6, .7, .8, .9, 1],
    }

    let map = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.map.resize()
          console.log("Set map height")
        }
      })
    }
    let observer = new IntersectionObserver(map, options)
    observer.observe(this.mapContainer)
  }

}

export default MapBox;