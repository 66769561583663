class SearchTabs {

  constructor() {
    this.listen()
  }

  listen() {
    let list = document.querySelector('.search-page__collections')
    list.addEventListener('click', (ev) => {
      if (ev.target.hasAttribute('data-tab')) {
        const active = list.querySelector('.active')
        if (active) active.classList.remove('active')
        ev.target.classList.add('active')
        document.querySelectorAll('[data-collection]').forEach(collection => {
          if (collection.dataset.collection == ev.target.dataset.tab) {
            collection.classList.remove('hidden');
          } else {
            collection.classList.add('hidden')
          }
        })
      }
    })
  }

}

export default SearchTabs