class VideoHandler {

  constructor(parent) {
    this.parent = parent
    this.aside = this.parent.querySelector('.service-videos__playlist')
    this.baseUrl = "https://dreambroker.com/channel/e2xnf1ig/iframe/"
    this.listen()
    this.openFirstMenu()
    if (window.innerWidth >= 1024) {
      this.parent.querySelector('iframe').addEventListener('load', () => {
        setTimeout(() => {
          this.setPlaylistHeight()
        }, 1000)
      })
    }
    this.resizeInViewport()
  }

  listen() {
    // Open playlist
    this.parent.querySelector('.service-videos__open-aside').addEventListener('click', () => {
      this.aside.classList.add('service-videos__playlist--open')
      document.documentElement.style = "overflow: hidden";
      const bg = document.querySelector('.faded-bg--inactive')
      setTimeout(() => {
        bg.classList.remove('faded-bg--inactive')
      }, 500)
    })

    // Close playlist
    this.parent.querySelector('.service-videos__close-aside').addEventListener('click', () => {
      document.documentElement.removeAttribute('style')
      this.aside.classList.remove('service-videos__playlist--open')
    })
    document.querySelector('.faded-bg').addEventListener('click', (ev) => {
      document.documentElement.removeAttribute('style')
      this.aside.classList.remove('service-videos__playlist--open')
      ev.target.classList.add('faded-bg--inactive')
    })

    this.parent.querySelector('.service-videos__list').addEventListener('click', ev => {
      // Click on Playlist item
      if (ev.target.classList.contains('service-videos__button')) {
        const video_id = ev.target.getAttribute('data-id')
        const iframe = this.parent.querySelector('iframe')
        iframe.src = this.baseUrl + video_id + "?autoplay=1"
        const current = this.aside.querySelector('.service-videos__button--active')
        current.classList.remove('service-videos__button--active')
        ev.target.classList.add('service-videos__button--active')
        this.aside.classList.remove('service-videos__playlist--open')
        if (document.documentElement.hasAttribute('style')) {
          document.documentElement.removeAttribute('style')
        }
      }
      // Click on Playlist header
      if (ev.target.classList.contains('service-videos__header-button')) {
        if (ev.target.classList.contains('service-videos__header-button--open')) {
          ev.target.nextElementSibling.removeAttribute('style')
          ev.target.classList.remove('service-videos__header-button--open')
        } else {
          ev.target.classList.add('service-videos__header-button--open')
          ev.target.nextElementSibling.style.maxHeight = ev.target.nextElementSibling.scrollHeight + "px"
        }
      }
    })

    // Recalculate playlist height on resize
    addEventListener('resize', () => {
      if (window.innerWidth >= 1024) {
        this.setPlaylistHeight()
      } else {
        this.aside.removeAttribute('style')
      }
    })

  }

  openFirstMenu() {
    const list = this.aside.querySelector('.service-videos__header-button--open ~ .service-videos__sublist')
    list.style.maxHeight = list.scrollHeight + "px"

  }

  setPlaylistHeight() {
      this.aside.style.maxHeight = (this.parent.querySelector('iframe').scrollHeight / 16) + "rem"
  }

  resizeInViewport() {
    let options = {
      root: null,
      rootMargin: "0px",
      threshold: [0, .1, .2, .3, .4, .5, .6, .7, .8, .9, 1],
    }

    let target = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          console.log("Set height")
          this.setPlaylistHeight()
        }
      })
    }
    let observer = new IntersectionObserver(target, options)
    observer.observe(this.parent)
  }

}

export default VideoHandler