/**
  * Smooth scroll to any table on the specifications tab
  */
class TableNavigation {
  constructor() {
    this.firstTable = document.querySelector('.product-table')
    this.section = this.firstTable.parentElement
    this.generateNav()
  }

  generateNav() {
    const headings = this.section.querySelectorAll('h3[id].product-table__heading')
    this.section.insertAdjacentHTML('afterbegin', '<nav class="product-table__anchors">')
    const nav = this.section.querySelector('.product-table__anchors')
    headings.forEach(heading => {
      nav.insertAdjacentHTML('beforeend', `<a class="product-table__anchor" href="#${heading.id}">${heading.innerHTML}</a>`)
    })
    this.section.insertAdjacentHTML('beforeend', '</nav>')
  }
}

export default TableNavigation