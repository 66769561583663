class Navigation {

  constructor() {
    this.sublists = document.querySelector('.nav__sublists')
    this.fade_layer = document.querySelector('.faded-bg')
    this.current, this.previous
    this.listen()
    this.setCurrentNav()
  }

  listen() {
    addEventListener('click', (ev) => {
      this.current = ev.target
      // Close menus
      if (ev.target.classList.contains('faded-bg')) {
        this.closeMenus()
      }
      if (this.current.classList.contains('nav__item--active') && this.current === this.previous) {
        this.closeMenus()
        this.previous = null
        this.current = null
        return
      }
      // Open mobile menu
      if (ev.target.classList.contains('menu-button')) {
        const main_nav = document.querySelector('.nav__grid')
        const open = main_nav.classList.contains('nav__grid--open')
        if (!open) {
          main_nav.classList.add('nav__grid--open')
          this.fade_layer.classList.remove('faded-bg--inactive')
          document.body.style = "overflow: hidden"
        } else {
          this.fade_layer.classList.add('faded-bg--inactive')
          main_nav.classList.remove('nav__grid--open')
        }
      }

      // Toggle submenus
      if (ev.target.classList.contains('nav__button')) {
        if (this.fade_layer.classList.contains('faded-bg--inactive')) {
          this.fade_layer.classList.remove('faded-bg--inactive')
          document.body.style = "overflow: hidden"
        }
        let parent = ev.target
        let submenu = this.sublists.querySelector(`[data-list="${ev.target.getAttribute('data-pointer')}"]`)
        const list_container = submenu.closest('[data-level]')
        const level = list_container.getAttribute('data-level')
        if (level === "2") {
          if (document.querySelector('.nav__sublist--open')) {
            this.closeMenus()
          }
        }
        if (level === "3") {
          if (list_container.querySelector('.nav__sublist--open')) {
            list_container.querySelector('.nav__sublist--open').classList.remove('nav__sublist--open')
            document.querySelectorAll('.nav__item--active').forEach(item => item.classList.remove('nav__item--active'))
          }
        }
        submenu.classList.add('nav__sublist--open')
        this.sublists.classList.add('nav__sublists--show')
        // desktop:
        if (level === "2" || level === "3") {
          parent.classList.add('nav__item--active')
          document.body.style = "overflow: hidden"
        }
        if (level === "3") {
          const level_2 = document.querySelector('[data-level="2"]')
          level_2.classList.add('shrink')
          list_container.classList.add('open')
        }
      }
      // Go back on mobile
      if (ev.target.classList.contains('nav__back')) {
        ev.target.closest('.nav__sublist--open').classList.remove('nav__sublist--open')
        if (!document.querySelector('.nav__sublist--open')) {
          document.querySelector('.nav__sublists--show').classList.remove('nav__sublists--show')
        }
      }
      if (!ev.target.classList.contains('nav__button')
        && !ev.target.classList.contains('nav__sublists--show')
        && !ev.target.classList.contains('menu-button')
        && !ev.target.classList.contains('nav__back')
        && ev.target.nodeName !== "A") {
        this.closeMenus()
        return
      }

      if (ev.target.nodeName === "A") {
        let clickedPath = ev.target.getAttribute('href').split("#")[0]
        let currentPath = location.pathname

        if (clickedPath === currentPath) {
          let sectionSelector = ev.target.href.slice(ev.target.href.search("#"))
          let section = document.querySelector(sectionSelector)
          // deactivate current tab
          let current = document.querySelector('[data-active]')
          current.removeAttribute('data-active')
          // activate clicked tab
          section.setAttribute('data-active', '')
          section.scrollIntoView()
          this.closeMenus()
        }
      }
      this.previous = this.current
    })
    addEventListener('keydown', ev => {
      if (ev.key === "Escape") {
        this.closeMenus()
      }
    })
  }

  closeMenus() {
    const sublists = document.querySelector('.nav__sublists--show')
    if (sublists) {
      sublists.querySelectorAll('.open').forEach(open => open.classList.remove('open'))
      sublists.querySelectorAll('.shrink').forEach(shrink => shrink.classList.remove('shrink'))
      sublists.classList.remove('nav__sublists--show')
      sublists.querySelectorAll('.nav__sublist--open').forEach(list => list.classList.remove('nav__sublist--open'))
    }
    document.querySelectorAll('.nav__item--active').forEach(item => item.classList.remove('nav__item--active'))
    const desktop_menu = document.querySelector('.nav__grid--open')
    if (desktop_menu) desktop_menu.classList.remove('nav__grid--open')
    // Fade layer skal IKKE skjules dersom
    // knappen man trykket på er en menyknapp, med mindre det er samme meny som er åpen
    if (this.current.classList.contains('nav__button') && this.previous.classList.contains('nav__button')
      && (this.current != this.previous)) {
      // Let the fade stay
    } else {
      this.fade_layer.classList.add('faded-bg--inactive')
    }
    document.body.removeAttribute('style')
  }

  setCurrentNav() {
    const current = document.querySelector('.nav__item--current')
    let level
    let button
    let parent
    if (current) {
      level = current.getAttribute('data-depth')
      parent = current.closest('[data-list]')
    }
    if (parent) {
      button = document.querySelector(`[data-pointer="${parent.getAttribute('data-list')}"]`)
    }
    if (level == 3) {
      let pointer = button.closest('[data-list]').getAttribute('data-list')
      button = document.querySelector(`[data-pointer="${pointer}"]`)
    }
    if (button) {
      button.parentElement.classList.add('nav__item--current')
    }
  }

}

export default Navigation