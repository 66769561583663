class InfoBox {

  constructor() {
    this.listen()
  }

  listen() {
    addEventListener('click', (ev) => {
      if (ev.target === document.querySelector('.product-table__info-button.open')) {
        this.close()
      } else if (ev.target.classList.contains('product-table__info-button')) {
        ev.target.innerHTML = "&times;"
        ev.target.classList.add('open')
        ev.target.nextElementSibling.classList.add('open')
        document.body.style.overflow = "hidden"
        document.querySelector('.faded-bg--inactive').classList.remove('faded-bg--inactive')
      }
      if (ev.target.classList.contains('faded-bg')) {
        this.close()
      }
    })
    addEventListener('keydown', ev => {
      if (ev.key === "Escape") {
        this.close()
      }
    })
  }

  close() {
    const open = document.querySelector('.infobox.open')
    if (open) {
      open.previousElementSibling.innerHTML = "i"
      open.previousElementSibling.classList.remove('open')
      open.classList.remove('open')
      document.body.removeAttribute('style')
    }
  }

}

export default InfoBox